<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="whatsapp">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to List WhatsApp
        </b-button>
    </div>
    <div class="row">
        <div class="col-md-7">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create WhatsApp Blast</h4>
                    </div>
                    <b-form-checkbox id="checkbox-1" v-model="formInput.send_now" name="checkbox-1" :value="true" :unchecked-value="false">
                        Send now
                    </b-form-checkbox>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="" class="mt-2">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info"> Index No. Handphone </label>
                                <input type="number" class="form-control" required min="0" v-model="formInput.nomor_handphone_index" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-if="!formInput.send_now">
                                <label class="text text-info"> Date </label><br />
                                <b-form-datepicker id="example-datepicker" v-model="formInput.send_date" class="mb-2"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-if="!formInput.send_now">
                                <label class="text text-info"> Time </label><br />
                                <b-time v-model="formInput.send_time" locale="id" class="border rounded p-2" :hour12="false"></b-time>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="text text-info"> File Excel (.xlsx) </label>
                                <b-form-file :disabled="formInput.name_file.length > 0" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-on:change="onFileChange($event)"></b-form-file>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card">
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="" class="mt-1">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="text text-info"> Message </label>
                                <textarea v-model="formInput.body_message" rows="12" class="form-control" placeholder="Your message $[0] "></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer border-0 pt-0">
                    <!-- <p class="card-text d-inline">Card footer</p> -->
                    <button type="button" class="btn btn-outline-success mb-1 mr-1 float-right" @click="handleSubmit"><i class="fa fa-save  mr-1"></i> Save Blast </button>
                </div>
            </div>
        </div>
        <div class="col-md-12" :hidden="!formInput.content_file">
            <div class="card">
                <div class="card-body">
                    <excel-viewer ref="excelViewer" :height="600" :first-row-num="1" :min-col-counts="25" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import "bootstrap-vue/dist/bootstrap-vue.css";
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css'
import {
    BTime
} from 'bootstrap-vue'

export default {
    name: 'CreateWhatsapp',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create WhatsApp Blast | %s',
    },
    components: {
        BTime,
    },
    data() {
        return {
            formInput: {
                nomor_handphone_index: 0,
                name_file: "",
                content_file: "",
                body_message: "",
                send_date: '',
                send_time: '',
                send_now: true,
                service: 'WA'
            }
        }
    },
    methods: {

        async onFileChange(event) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.formInput.content_file = reader.result;
                this.formInput.name_file = event.target.files[0].name;
            };

            // await this.$nextTick()
            this.$nextTick(() => {
                this.$refs.excelViewer.openExcelFile(event.target.files[0]);
            });

        },

        async handleSubmit() {

            this.$store.dispatch("setLoading", true);
            axios.post('message-service/upload-file', this.formInput, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                }).then(resp => {
                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                    } else {
                        this.$swal.fire("Error ", resp.data.message, "error");
                    }
                })
                .catch(error => {
                    this.$store.dispatch("setLoading", false);
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                            } else {
                                this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }
                    } else {
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    }

                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
