<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>


<script>
import axios from "axios"
import Dashboard from '../views/BroadcastModule/Dashboard'
import Whatsapp from '../views/BroadcastModule/WhatsApp/Whatsapp'
import CreateWhatsapp from '../views/BroadcastModule/WhatsApp/CreateWhatsapp'
import DetailWhatsapp from '../views/BroadcastModule/WhatsApp/DetailWhatsapp'

export default {
    name: 'Broadcast',
    components: {
        Dashboard,
        Whatsapp,
        CreateWhatsapp,
        DetailWhatsapp
    },
    async created() {
        try {
            await this.$store.dispatch('setLoading', true);
            const resp = await axios.get("user-service/auth/menu?module=broadcast", {
                headers: {
                    Authorization: localStorage.getItem("token") || "",
                },
            });
            if (resp.data.error === false) {
                this.listApplication = resp.data.data;
                await this.$store.dispatch('setMenu', resp.data.data);
                return;
            }
        } catch (error) {
            if (error.response != undefined) {
                if (error.response.data != undefined) {
                    if (error.response.data.error_auth != undefined) {
                        this.$swal.fire("Get Application List", error.response.data.message, "error");
                        this.$store.dispatch("logOut").then(() => {
                            this.$router.push("/login");
                        });
                        return;
                    }
                    return this.$swal.fire("Get Application List ", error.response.data.message, "error");
                }
            }

            this.$sentry.captureException(error);
            this.$swal.fire("Error", error.message, "error");
        } finally {
            this.$store.dispatch("setApp");
            this.$store.dispatch('setLoading', false);
        }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>